<template>
  <transition name="fade" v-if="qrScannerActive">
    <section class="screen-modal qr-code-modal">
      <div class="modal-backdrop"></div>
      <div class="modal-content">
        <section class="modal-inner-content">
          <div ref="qrVideo" id="reader" class="reader"></div>
          <template v-if="cameras.length">
            <h5 class="reader-title">
              {{ displayLabelName("plan", "plan", "select-a-camera") }}
            </h5>
          </template>
        </section>
        <footer class="modal-footer">
          <ul class="cameras" :class="{ active: camerasMenu }">
            <li v-for="camera in cameras" :key="camera.id" class="camera-item">
              <button class="scan-button" @click="getUserMedia(camera.id)">
                {{ camera.label }}
              </button>
            </li>
          </ul>
          <nav class="actions-menu theme-gray">
            <ul class="actions-list">
              <li class="action">
                <button
                  class="action-btn"
                  @click="
                    () => {
                      cancelStream();
                      closeReader();
                    }
                  "
                >
                  {{ displayLabelName("plan", "plan", "cancel") }}
                </button>
              </li>
              <template v-if="streaming && zoom">
                <li class="action">
                  <button class="action-btn" @click="increaseZoom">
                    {{ displayLabelName("plan", "plan", "zoom-in") }}
                  </button>
                </li>
                <li class="action">
                  <button class="action-btn" @click="deceaseZoom">
                    {{ displayLabelName("plan", "plan", "zoom-out") }}
                  </button>
                </li>
              </template>
              <li
                class="action"
                v-if="cameras && cameras.length > 0 && streaming"
              >
                <button class="action-btn" @click="toggleCameraMenu">
                  {{ displayLabelName("plan", "plan", "camera") }}
                </button>
              </li>
            </ul>
          </nav>
        </footer>
      </div>
    </section>
  </transition>
</template>

<script>
import { errorHandler } from "@/services/error-handler";
import { mapState, mapActions } from "vuex";
import { BrowserQRCodeReader } from "@zxing/browser";
import { getScreenId } from "@/services/helpers";
import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";

export default {
  name: "QrCodeInterface",
  data() {
    return {
      codeReader: new BrowserQRCodeReader(),
      cameras: [],
      scanData: null,
      camerasMenu: false,
      zoom: null,
      zoomMin: 0,
      zoomMax: 0,
      zoomStep: 0,
      track: null,
      supportsCameraApi:
        "mediaDevices" in navigator && "getUserMedia" in navigator.mediaDevices,
      streaming: false
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getUserMedia();
      this.getDevices();
    });
  },
  watch: {
    $route() {
      this.cancelStream();
    },
    scanData() {
      this.fetchQrCodeData(this.scanData);
      return this.cancelStream();
    }
  },
  computed: {
    ...mapState("visitorManagement", [
      "qrScannerActive",
      "visitors",
      "scanResource"
    ])
  },
  methods: {
    ...mapActions("visitorManagement", [
      "setScanResource",
      "pushToModalQueue",
      "setModalQueue",
      "setSearchQuery"
    ]),
    ...mapActions("visit", ["getVisit"]),
    fetchQrCodeData(url) {
      const qrCode = url.split("/").pop();
      if (this.scanResource) {
        this.assignResourceTouser(qrCode);
      } else {
        this.getVisitFromQrCode(qrCode);
      }

      // close QR Code reader
      this.closeReader();
    },
    assignResourceTouser(qrcode) {
      if (qrcode) {
        this.$store.commit("visitorManagement/setScannedQRCode", qrcode, {
          root: true
        });
      }
      this.setScanResource(false);
    },
    getVisitFromQrCode(qrCode) {
      const self = this;

      this.$store.commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .get(`${apiEndpoints.company.qrCodeScan}/${qrCode}`)
        .then(response => {
          if (
            !response.data ||
            !response.data.data ||
            !response.data.data.visit_id
          ) {
            return;
          }
          let visitId = response.data.data.visit_id;
          const { receptionDeskId } = this.$route.params;
          return httpServiceAuth.get(
            `${apiEndpoints.company.visits}/${visitId}/${receptionDeskId}/visitor`
          );
        })
        .then(response => {
          if (!response.data || !response.data.data) {
            return self.displayGenericMessage();
          }
          const visitor = response.data.data;
          this.setModalQueue([visitor]);
          this.$store.commit("visitorManagement/setShowStatusDialog", true, {
            root: true
          });
          // Open Visit edit page
          this.$router.push({
            name: "r_reception-desk-edit-visitor-edit-visit",
            params: {
              visitorId: visitor.id,
              visitId: visitor.visit.id
            }
          });
        })
        .finally(() => {
          this.$store.commit("loader/setScreenLoading", false, { root: true });
        });
    },
    displayGenericMessage(error) {
      return errorHandler({
        data: {
          message: error
            ? error
            : this.displayLabelName("plan", "plan", "qr-code-error")
        }
      });
    },
    setZoomOptions(track) {
      const capabilities = track.getCapabilities();
      const settings = track.getSettings();
      if ("zoom" in settings) {
        this.zoom = settings.zoom;
        this.zoomMin = capabilities.zoom.min;
        this.zoomMax = capabilities.zoom.max;
        this.zoomStep = capabilities.zoom.step;
      }
    },
    increaseZoom() {
      if (this.zoom < this.zoomMax) {
        this.zoom += this.zoomStep;
        this.track.applyConstraints({
          advanced: [{ zoom: this.zoom }]
        });
      }
    },
    deceaseZoom() {
      if (this.zoom > this.zoomMin) {
        this.zoom -= this.zoomStep;
        this.track.applyConstraints({
          advanced: [{ zoom: this.zoom }]
        });
      }
    },
    async getUserMedia(deviceId) {
      this.camerasMenu = false;
      if (this.supportsCameraApi) {
        const self = this;
        let constraints = {
          audio: false,
          video: true,
          focusMode: "continuous"
        };
        if (deviceId) {
          constraints.deviceId = deviceId;
        } else {
          constraints = {
            ...constraints,
            video: {
              facingMode: "environment"
            }
          };
        }
        navigator.mediaDevices
          .getUserMedia(constraints)
          .then(function(stream) {
            self.track = stream.getVideoTracks()[0];
            self.setZoomOptions(self.track);
            self.handleStream(stream);
          })
          .catch(function(err) {
            self.displayGenericMessage(err);
          });
      }
    },
    async getDevices() {
      if (this.supportsCameraApi) {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = [];
        devices.forEach(function(device) {
          if (device.kind === "videoinput") {
            videoDevices.push({
              id: device.deviceId,
              label: device.label
            });
          }
        });
        this.cameras = videoDevices;
      }
    },
    handleStream(stream) {
      const self = this;
      const element = this.$refs.qrVideo;
      let video = element.querySelector("video");

      if (!video) {
        video = document.createElement("video");
        video.style.width = "100%";
        video.style.height = "100%";
        video.setAttribute("muted", true);
        video.playsInline = true;
        element.append(video);
        video.addEventListener("canplay", self.setStreaming, false);
      }
      video.srcObject = stream;
      video.play();
    },
    setStreaming() {
      if (!this.streaming) {
        const video = this.$refs.qrVideo.querySelector("video");
        let width = 373;
        let height = 0;
        height = video.videoHeight / (video.videoWidth / width);
        video.setAttribute("width", width);
        video.setAttribute("height", height);
        this.streaming = true;
        this.decodeOnce();
      }
    },
    resetState() {
      this.cameras = [];
      this.streaming = false;
      this.zoom = null;
      this.zoomMin = 0;
      this.zoomMax = 0;
      this.zoomStep = 0;
      this.track = null;
      this.$store.commit("qrCode/setQrActive", false, { root: true });
      this.$store.commit("qrCode/setQrScreenId", null, { root: true });
    },
    cancelStream() {
      const videoEl = this.$refs.qrVideo.querySelector("video");
      this.track && this.track.stop();
      if (videoEl) {
        videoEl.removeEventListener("canplay", this.setStreaming);
        videoEl.remove();
      }
      this.resetState();
    },
    closeReader() {
      // close QR Code reader
      this.$store.commit("visitorManagement/setQrScreenActive", false, {
        root: true
      });

      this.$store.commit(
        "visitorManagement/setQrScannerScreenId",
        getScreenId(this.findElement()),
        {
          root: true
        }
      );
    },
    toggleCameraMenu() {
      if (!this.camerasMenu) {
        this.track.stop();
      }
      this.camerasMenu = !this.camerasMenu;
    },
    async decodeOnce() {
      const el = this.$refs.qrVideo.querySelector("video");
      this.codeReader.scan(el, (result, _, controls) => {
        // use the result and error values to choose your actions
        // you can also use controls API in this scope like the controls
        // returned from the method.
        if (result) {
          controls.stop();
          this.scanData = result.getText();
        }
      });
    }
  },
  beforeUnmount() {
    this.cancelStream();
  }
};
</script>
<style scoped>
.reader {
  width: 373px !important;
}
</style>
